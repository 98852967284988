import React from 'react'
import '../styles/main.css'
import '../styles/index.css'
import Layout from '../components/layout'
import Seo from '../components/seo'
//import mixpanel from 'mixpanel-browser'

const PageContent = () => (
    <Layout>
        <Seo>
            {/*<script data-host="https://microanalytics.io" data-dnt="false" src="https://microanalytics.io/js/script.js" id="ZwSg9rf6GA" async defer></script>*/}
        </Seo>
        <main id="banner">
            <div class="inner"><h2>Tissad Group</h2><p>Research. Advisory. Consulting.</p></div>
        </main>
    </Layout>
)

//mixpanel.init('a32526dda925b161ad0a591aff80110f', { debug: true });
//mixpanel.track('Home');

export default PageContent
